import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import 'lib-flexible';

// Vant
import Vant from 'vant';
// import 'vant/lib/index.css';
Vue.use(Vant);

// element-ui
// import 'element-ui/lib/theme-chalk/index.css';
import ELEMENT from 'element-ui';
Vue.use(ELEMENT);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
