<template>
	<div id="app" class="container">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style lang="less">
	// @font-face {
	// 	font-family: 'hwxh';
	// 	src: url('./assets/font/hwxh.ttf');
	// }

		body {	
			font-family: '微软雅黑', 'Helvetica' !important;
		}
</style>