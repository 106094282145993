import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config: config,
        imgBaseURL: process.env.BASE_URL,
        // business
        businessBanners: [],
        // 当前nav
        nav: '',
        navs: []
    },
    getters: {
        currentNav (state) {
            if (state.nav == '') {
                let nav = sessionStorage.getItem('currentNav');
                if (nav != null) {
                    nav = JSON.parse(nav);
                    state.nav = nav;
                    return nav;
                }
            }
            return state.nav;
        },
        selfNavs (state) {
            if (state.navs,length == 0) {
                let navs = sessionStorage.getItem('navs');
                if (navs != null) {
                    navs = JSON.parse(navs);
                    state.navs = navs;
                    return navs;
                }
            }
            return state.navs;
        }
    },
    mutations: {
        setBusinessBanners (state, banners) {
            state.businessBanners = banners;
        },
        setNav (state, nav) {
            state.nav = nav;
            sessionStorage.setItem('currentNav', JSON.stringify(nav));
        },
        setNavs (state, navs) {
            state.navs = navs;
            sessionStorage.setItem('navs', JSON.stringify(navs));
        }
    },
    actions: {}
})