import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: '',
            redirect: '/index'
        },
        {
            path: '/index',
            component: () => import('@/pages'),
            children: [
                {
                    path: '',
                    redirect: '/home'
                },
                {
                    path: '/home',
                    component: () => import('@/pages/home')
                },
                {
                    path: '/cooperation',
                    component: () => import('@/pages/cooperation')
                },
                {
                    path: '/industry',
                    component: () => import('@/pages/industry')
                },
                {
                    path: '/contact',
                    component: () => import('@/pages/contact')
                },
                {
                    path: '/recruit',
                    component: () => import('@/pages/recruit')
                },
                {
                    path: '/business',
                    component: () => import('@/pages/business'),
                    children: [
                        {
                            path: '',
                            redirect: 'evaluation'
                        },
                        {
                            path: 'evaluation',
                            component: () => import('@/pages/evaluation')
                        },
                        {
                            path: 'wisdom',
                            component: () => import('@/pages/wisdom')
                        },
                        {
                            path: 'record',
                            component: () => import('@/pages/record')
                        },
                        {
                            path: 'train',
                            component: () => import('@/pages/train')
                        },
                        {
                            path: 'programme',
                            component: () => import('@/pages/programme')
                        },
                        {
                            path: 'manager',
                            component: () => import('@/pages/manager')
                        },
                        {
                            path: 'build',
                            component: () => import('@/pages/build')
                        },
                        {
                            path: 'suggest',
                            component: () => import('@/pages/suggest')
                        }
                    ]
                }
            ]
        }
    ]
});